import { useEffect, useRef, useCallback, useState } from 'react';
import debounce from 'lodash.debounce'

const VideoPlayerComponent = ({ id, publicId, className="", classNameContainer="", autoplay=true, startOffset, setVideoRef, playIfFocus, currentTime, touchEndCallback }) => {
  const videoRef = useRef();
  const [canTouchEndCallback, setCanTouchEndCallback] = useState(true);

  useEffect(() => {
    if(videoRef.current){
      window.cloudinary.videoPlayer(videoRef.current, {
        cloud_name: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
        secure: true,
        controls: true,
        muted: true,
        hideContextMenu: true,
        autoplay: autoplay,
        loop: true,
        transformation:{
          start_offset: startOffset
        }
      });
      if(setVideoRef){
        setVideoRef(videoRef);
      }
      debouncedStart();
    }
  }, [videoRef]);

  const mouseEnter = (e) => {
    if(playIfFocus){
      videoRef.current.play();
    }
  }

  const stopVideo = () => {
    if(!!videoRef?.current){
      videoRef.current.pause();
    }
  }

  const mouseLeave = (e) => {
    if(playIfFocus){
      stopVideo();
    }
  }

  const debouncedStartVideo = () => {
    if(!!videoRef?.current && (!!currentTime || currentTime == 0)){
      try{
        videoRef.current.currentTime = currentTime;
        let play = videoRef.current.play();
        if(play !== undefined){
          play.then(x => {
          debouncedStop();
          });
        }
      }catch(e){
        console.log(e)
      }
    }
  }

  const touchStart = (e) => {
    e.stopPropagation()
    // if(playIfFocus){
    //   debouncedKeepTouching();
    // }
  }

  const touchEnd = (e) => {
    if(playIfFocus){
      stopVideo();
    }
    if(touchEndCallback && canTouchEndCallback){
      touchEndCallback(e);
    }
    setCanTouchEndCallback(true)
  }

  const touchMove = (e) => {
    setCanTouchEndCallback(false)
  }

  const keepTouching = () =>{
    videoRef.current.play();
  }


  const debouncedStart = useCallback(debounce(debouncedStartVideo, 300), []);
  const debouncedStop = useCallback(debounce(stopVideo, 10), []);
  const debouncedKeepTouching = useCallback(debounce(keepTouching, 1000), []);


  return (
    <div className={classNameContainer} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} onTouchMove={touchMove} onTouchStart={touchStart} onTouchEnd={touchEnd} 
    onTouchCancel={touchEnd} >
      <video
        ref={videoRef}
        id={id}
        className={className}
        data-cld-public-id={publicId}
      />
    </div>
  )
}

export default VideoPlayerComponent;