import React from "react";
import { useI18nContext } from "../../../../i18n/context/context";
import AutoCompleteDirectiveComponent from "../../../../directives/AutoCompleteDirectiveComponent";
import MenuItemEditPriceComponent from "./MenuItemEditPriceComponent";
import { generateGuid } from '../../../../helpers/formatting';


function MenuItemEditPriceVariantComponent({ priceVariants, variants, setVariant, currencies }) {
    const { translate, language } = useI18nContext();  

    const setPriceFunction = (id, idx, val) => {
      const myPriceVariants = [...priceVariants];
      
      if(myPriceVariants[id].prices[idx] != null) {
        myPriceVariants[id].prices[idx].value = val;
      } else {
        myPriceVariants[id].prices.push({value: val, currency: ""})
      }

      myPriceVariants[id].prices = myPriceVariants[id].prices.filter(x => !!x.value)

      setVariant(id, myPriceVariants[id])
    }
  
    const setCurrencyFunction = (id, idx, curr) => {
      const myPriceVariants = [...priceVariants];
      myPriceVariants[id].prices[idx].currency = curr;
      setVariant(id, myPriceVariants[id])
    }


    const onChangeVariantValue = (id, val) => {
      const myPriceVariants = [...priceVariants];
      if(myPriceVariants[id] != null) {
        let myVariant = myPriceVariants[id]
        myVariant.variant = val?.text;
        myVariant.id = val?.value;
        setVariant(id, myVariant)
      } else {
        setVariant(id, {variant: val?.text, id: val?.value, prices: []})
      }
    }

    return (
        <div className="">
            {!!priceVariants && 
                <div className="">
                  {[...priceVariants, { variant: '', id: `new_${generateGuid()}` }].map((item, idx) => (
                    <div key={idx} className="display-flex">
                      <div className="admin-price-variant">
                        <AutoCompleteDirectiveComponent 
                          value={!!item && {value: item.id, text: item.variant}} 
                          onChange={(val) => onChangeVariantValue(idx, val)} 
                          placeholder={translate("variant")}
                          options={variants}
                        />
                      </div>
                      {!!item?.variant &&
                        <div className="admin-price-variant-prices">
                          <MenuItemEditPriceComponent prices={item.prices} currencies={currencies} setPrice={(id, val) => setPriceFunction(idx, id, val)} setCurrency={(id, val) => setCurrencyFunction(idx, id, val)} />
                        </div>
                      }
                    </div>
                  ))}
                </div>
              }
        </div>
    );
}

export default MenuItemEditPriceVariantComponent;
