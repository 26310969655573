import _ from 'lodash';
import { useCallback, useMemo, useState } from "react";

const getInitialFilters = (currency) => ({
  category: null,
  size: null,
  minPrice: null,
  maxPrice: null,
  dishType: null,
  currency,
  includedItems: [],
  excludedItems: [],
  allergens: [],
  lactoseFree: false,
  glutenFree: false,
  vegetarian: false,
  vegan: false,
});

export const useDishesFilter = (defaultCurrency) => {
  const [filters, setFilters] = useState(getInitialFilters(defaultCurrency));

  const isFilterActive = useMemo(() => !!(
    filters.minPrice 
      || filters.maxPrice 
      || (filters.includedItems && filters.includedItems.length > 0)
      || (filters.excludedItems && filters.excludedItems.length > 0)
      || (filters.allergens && filters.allergens.length > 0)
      || filters.category 
      || filters.dishType 
      || filters.size 
      || filters.lactoseFree
      || filters.glutenFree
      || filters.vegetarian
      || filters.vegan
  ), [filters]);

  const handleFiltersChange = useCallback((filter) => {
    setFilters({
      ...filters,
      ...filter
    })
  }, [filters])


  const filterByPrice = (item, filters) => {
    if(filters.size){
      let variant = item.priceVariants.filter(x => x.id == filters.size)

      if(variant.length == 0){
        return false
      }

      if(!filters.minPrice && !filters.maxPrice){
        return !!(variant?.length > 0)
      }
      return !!(variant[0].prices?.filter(x => x.currency == filters.currency && (!filters.minPrice || x.value >= filters.minPrice) && (!filters.maxPrice || x.value <= filters.maxPrice))?.length > 0)
    }

    if(!filters.minPrice && !filters.maxPrice){
      return true
    }

    if(item.priceVariants.length > 0){
      return !!(item.priceVariants.filter(variant => variant.prices.filter(x => x.currency == filters.currency && (!filters.minPrice || x.value >= filters.minPrice) && (!filters.maxPrice || x.value <= filters.maxPrice))?.length > 0)?.length > 0)
    }
    
    return !!(item.prices.filter(x => x.currency == filters.currency && (!filters.minPrice || x.value >= filters.minPrice) && (!filters.maxPrice || x.value <= filters.maxPrice))?.length > 0)
  };

  const filter = (items, filters) => {
    return items?.filter(item => (
      filterByPrice(item, filters)
      && (!filters.includedItems || filters.includedItems?.every(elem => item.dishItems.map(({value}) => value).includes(elem.value)))
      && (!filters.excludedItems || filters.excludedItems?.every(elem => !item.dishItems.map(({value}) => value).includes(elem.value)))
      && (!filters.allergens || filters.allergens.every(elem => !item.allergens.includes(elem)))
      && (!filters.category || filters.category === item.category?.value)
      && (!filters.dishType || filters.dishType === item.dishType?.value)
      && (!filters.size || item.priceVariants.some(({ id }) => filters.size && id))
      && (!filters.lactoseFree || item.isLactoseFree === true)
      && (!filters.glutenFree || item.isGlutenFree === true)
      && (!filters.vegetarian || item.isVegetarian === true)
      && (!filters.vegan || item.isVegan === true)
    ));
  };

  const resetFilters = () => {
    setFilters(getInitialFilters());
  }

  return {
    filters,
    isFilterActive,
    filter,
    setFilters: handleFiltersChange,
    resetFilters
  };
};
