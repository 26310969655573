// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.restaurant-start-container {
  width: 100vw;
  height: 100vh;
  background-color: #000000dd;
  overflow-x: auto;
  overflow-y: hidden;
  position: fixed;
  inset: 0;
  cursor: pointer;
  z-index: 99;
}

.restaurant-start-inside {
  z-index: 99;
  max-width: 60%;
  max-height: 95vh !important;
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
  margin: auto;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 100ms;
  box-shadow: var(--rs-panel-shadow);
  background-color: var(--backgroundColor);
  border-radius: 6px;
}

.restaurant-start-inside-inside{
  margin-top: 30px;
}

.restaurant-start-poster-inside {
  z-index: 99;
  width: 100vw;
  height: 100vh;
  background-color: var(--backgroundColor);
}

.restaurant-start-poster-inside-inside{
  margin: auto;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 1360px){
  .restaurant-start-inside {
    max-width: 70%;
  }
}

@media only screen and (max-width: 766px){
  .restaurant-start-inside {
    max-width: 95%;
    width: 95% !important;
    max-height: 70vh !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/restaurant/RestaurantStartComponent.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,2BAA2B;EAC3B,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;EACf,QAAQ;EACR,eAAe;EACf,WAAW;AACb;;AAEA;EACE,WAAW;EACX,cAAc;EACd,2BAA2B;EAC3B,sCAA8B;EAA9B,mCAA8B;EAA9B,8BAA8B;EAC9B,YAAY;EACZ,eAAe;EACf,SAAS;EACT,QAAQ;EACR,gCAAgC;EAChC,iBAAiB;EACjB,kCAAkC;EAClC,wCAAwC;EACxC,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,wCAAwC;AAC1C;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,SAAS;EACT,QAAQ;EACR,gCAAgC;AAClC;;AAEA;EACE;IACE,cAAc;EAChB;AACF;;AAEA;EACE;IACE,cAAc;IACd,qBAAqB;IACrB,2BAA2B;EAC7B;AACF","sourcesContent":[".restaurant-start-container {\n  width: 100vw;\n  height: 100vh;\n  background-color: #000000dd;\n  overflow-x: auto;\n  overflow-y: hidden;\n  position: fixed;\n  inset: 0;\n  cursor: pointer;\n  z-index: 99;\n}\n\n.restaurant-start-inside {\n  z-index: 99;\n  max-width: 60%;\n  max-height: 95vh !important;\n  height: fit-content !important;\n  margin: auto;\n  position: fixed;\n  left: 50%;\n  top: 50%;\n  transform: translate(-50%, -50%);\n  transition: 100ms;\n  box-shadow: var(--rs-panel-shadow);\n  background-color: var(--backgroundColor);\n  border-radius: 6px;\n}\n\n.restaurant-start-inside-inside{\n  margin-top: 30px;\n}\n\n.restaurant-start-poster-inside {\n  z-index: 99;\n  width: 100vw;\n  height: 100vh;\n  background-color: var(--backgroundColor);\n}\n\n.restaurant-start-poster-inside-inside{\n  margin: auto;\n  position: fixed;\n  left: 50%;\n  top: 50%;\n  transform: translate(-50%, -50%);\n}\n\n@media only screen and (max-width: 1360px){\n  .restaurant-start-inside {\n    max-width: 70%;\n  }\n}\n\n@media only screen and (max-width: 766px){\n  .restaurant-start-inside {\n    max-width: 95%;\n    width: 95% !important;\n    max-height: 70vh !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
