import "bootstrap/dist/css/bootstrap.min.css";
import { FaChevronLeft } from "react-icons/fa";
import logoPicture from "../../../assets/images/logo01.png"
import React, { useCallback } from "react";

function LogoBackComponent({ restaurant }) {
    const ChangeLocation = useCallback(() => {
      window.location.href = window.location.origin;
    }, []);

    const mouseDownHandler = ( event ) => {
      // if( event.button === 0 ) {
      //   window.location.href = window.location.origin;
      // }
      // if( event.button === 1 ) {
      //   window.open(window.location.origin, "_blank");
      // }
    }

  return (
    <div>
        {<div className="restaurant-back-container" onMouseDown={mouseDownHandler} >
          {/* {!!restaurant && !!restaurant.isDemo && <div className="restuarant-arrow-left">
            <FaChevronLeft size={30} />
          </div>} */}
          <div className="">
            <img src={logoPicture} className="logo-back-style" />
          </div>
          <div className="restaurant-go-to-text">Sefoo</div>
        </div>}
    </div>
  );
}

export default LogoBackComponent;
