import React, { useCallback, useRef, useState, useMemo, useEffect }  from "react";
import { useI18nContext } from "../../../i18n/context/context";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import L, {Icon} from 'leaflet'
import defMarker from "leaflet/dist/images/marker-icon.png"
import sefooMarker from '../../../assets/images/sefooMarker.png'
import redMarker from '../../../assets/images/redMarker.png'
import pinkMarker from '../../../assets/images/pinkMarker.png'
import purpleMarker from '../../../assets/images/purpleMarker.png'
import greenMarker from '../../../assets/images/greenMarker.png'
import yellowMarker from '../../../assets/images/yellowMarker.png'
import orangeMarker from '../../../assets/images/orangeMarker.png'
import brownMarker from '../../../assets/images/brownMarker.png'
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";


function AdminBasicMapComponent({ restaurant, isEditable, setLocation }) {
  const [marker, setMarker] = useState(defMarker)

  const [position, setPosition] = useState({
    lat: restaurant?.location?.x,
    lng: restaurant?.location?.y
  })

  useEffect(() => {
    switch(restaurant.color) {
      case 'red':
        setMarker(redMarker)
        break;
      case 'pink':
        setMarker(pinkMarker)
        break;
      case 'purple':
        setMarker(purpleMarker)
        break;
      case 'green':
        setMarker(greenMarker)
        break;
      case 'yellow':
        setMarker(yellowMarker)
        break;
      case 'orange':
        setMarker(orangeMarker)
        break;
      case 'brown':
        setMarker(brownMarker)
        break;
      default:
        setMarker(sefooMarker)
    }
  }, [restaurant])

  const legalIcon = new Icon ({
    iconUrl: marker,
    iconSize: [40, 40]
  })

  const provider = new OpenStreetMapProvider();

  const searchControl = new GeoSearchControl({
    provider: provider,
  });

  // const map = L.map('map')
  // map.addControl(searchControl)

  function DraggableMarker () {
    const markerRef = useRef(null)
    const eventHandlers = useMemo(
      () => ({
        dragend() {
          const marker = markerRef.current
          if (marker != null) {
            let location = marker.getLatLng();
            setPosition(location);
            if(setLocation){
              setLocation(location)
            }
          }
        },
      }),
      [],
    )
    const toggleDraggable = useCallback(() => {
      setDraggable((d) => !d)
    }, [])
  
    return (
      <Marker
        draggable={true}
        eventHandlers={eventHandlers}
        position={position}
        ref={markerRef}
        icon={legalIcon}>
      </Marker>
    )
  }

  return (
    <div className="admin-basic-map-container">
      <MapContainer center={position}  zoom={15}  scrollWheelZoom={true} className="admin-basic-map">
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <DraggableMarker />
      </MapContainer>
    </div>
    
  );
}

export default AdminBasicMapComponent;
