import "./InputDirectiveStyles.css";
import "./InputHoursDirectiveStyles.css"
import TimePicker from 'react-time-picker'
import 'react-time-picker/dist/TimePicker.css';
import { useState, useEffect, useCallback, useRef } from "react";
import { IoClose } from "react-icons/io5";

function InputHourDirectiveComponent({value, setValue, className}){
    const [isFocused, setIsFocused] = useState(false);
    const [generatedId, SetGeneratedId] = useState("");
    const [myValue, setMyValue] = useState(value);
    const myRef = useRef(null);

    const focusInput = useCallback((e) => {
        setIsFocused(true);
    }, [])

    const focusOutInput = (e) => {
        setIsFocused(false);
    }

    const onChange = (val) => {
        setMyValue(val)
        if(setValue){
            setValue(val);
        }
    }

    useEffect(() => {
        if(!!myRef){
            let element = myRef.current.getBoundingClientRect();
            SetGeneratedId("inputDirective".concat(element.x).concat(element.y)); 
        }
    }, [myRef])

    return (
        <div id={generatedId} ref={myRef} className={`id-main ${className}`}>
            <div className={`id-input-hour-body ${!!isFocused ? "id-focused" : ""} ${!!myValue ? "id-hour-not-empty" : ""}`}>
                <TimePicker disableClock onFocus={focusInput} value={myValue} onChange={onChange} onBlur={focusOutInput} clearIcon={<IoClose className="id-clear-button"/>} />
            </div>
        </div>
    )
}

export default InputHourDirectiveComponent;