import "leaflet/dist/leaflet.css";
import React, { useEffect, useState, useRef } from "react";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import {Icon} from 'leaflet'
import { IoNavigate } from "react-icons/io5";
import { useI18nContext } from "../../../i18n/context/context";
import defMarker from "leaflet/dist/images/marker-icon.png"
import sefooMarker from '../../../assets/images/sefooMarker.png'
import redMarker from '../../../assets/images/redMarker.png'
import pinkMarker from '../../../assets/images/pinkMarker.png'
import purpleMarker from '../../../assets/images/purpleMarker.png'
import greenMarker from '../../../assets/images/greenMarker.png'
import yellowMarker from '../../../assets/images/yellowMarker.png'
import orangeMarker from '../../../assets/images/orangeMarker.png'
import brownMarker from '../../../assets/images/brownMarker.png'

function MapStaticComponent({positions, color}) {
  const { translate, language } = useI18nContext();
  const [position] = useState([positions.x, positions.y]);
  const [marker, setMarker] = useState(defMarker)
  const markerRef = useRef(null)

  useEffect(() => {
    markerRef.current?.openPopup()
  }, [markerRef.current])

  useEffect(() => {
    switch(color) {
      case 'red':
        setMarker(redMarker)
        break;
      case 'pink':
        setMarker(pinkMarker)
        break;
      case 'purple':
        setMarker(purpleMarker)
        break;
      case 'green':
        setMarker(greenMarker)
        break;
      case 'yellow':
        setMarker(yellowMarker)
        break;
      case 'orange':
        setMarker(orangeMarker)
        break;
      case 'brown':
        setMarker(brownMarker)
        break;
      default:
        setMarker(sefooMarker)
    }
  }, [color])

  const navigate = (e) => {
    window.open(`https://www.google.com/maps/dir/?api=1&destination=${position[0]},${position[1]}`)
  }

  const legalIcon = new Icon ({
    iconUrl: marker,
    iconSize: [40, 40]
  })
  
  const staticProps = {
    zoomControl: false,
    doubleClickZoom: false,
    closePopupOnClick: false,
    dragging: false,
    zoomSnap: false,
    zoomDelta: false,
    trackResize: false,
    touchZoom: false,
    scrollWheelZoom: false
  }

  return (
    <div className="map-container-style">
      <MapContainer center={position} zoom={15} className="map-container" >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={position} icon={legalIcon} ref={markerRef} >
          <Popup ><div className="map-popup-navigate" onClick={navigate}><IoNavigate className="margin-right-sm"/> {translate("navigate")}</div></Popup>
        </Marker>
      </MapContainer>
    </div>
  );
}

export default MapStaticComponent;
