/* eslint-disable react-hooks/exhaustive-deps */
import "../../App.css";
import "../../css/dish.css";
import "../../css/global.css";
import "../../css/admin.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect } from "react";
import NavBarScrollComponent from "../../components/base/head/NavBarScrollComponent";
import useScreenDetector from "../../hooks/useScreenDetector";
import { useI18nContext } from "../../i18n/context/context";
import AdminMenuComponent from "../../components/admin/menu/AdminMenuComponent";
import { useNavigate, useParams } from 'react-router-dom';
import AdminPicturesComponent from "../../components/admin/pictures/AdminPicturesComponent";
import AdminBasicComponent from "../../components/admin/basic/AdminBasicComponent";
import DescriptionEditorComponent from "../../components/admin/description/DescriptionEditorComponent";


function MyPage({ OpenEditModal, Restaurant }) {
  const { translate, language } = useI18nContext();
  const [restaurant, setRestaurant] = useState(Restaurant);
  const { restaurantCode } = useParams();
  
  const { sizeScreen } = useScreenDetector();
  const [tabs, setTabs] = useState([]);
  const [activeTab, SetActiveTab] = useState(null);

  useEffect(() => {
    const mTabs = ['menu', 'basicData', 'description', 'pictures'];
    setTabs(mTabs);
    if (!mTabs.includes(activeTab) || !activeTab) {
      SetActiveTab(mTabs[0]);
    }

  }, [sizeScreen])

  useEffect(() => {
    if(!!restaurant){
      setRestaurant(restaurant)
    }
    else{
      setRestaurant({
        id: null,
        code: "NewRestaurant",
        restaurantBasicData: {
          name: null
        },
        pictures: [],
        restaurantMenus: [{
          isActive: true,
          name: translate("default"),
          cards: [{
            id: null,
            code: "specialEmptyCard",
            language: language,
            name: translate("specialEmptyCard"),
            filters: {
              category: false,
              size: false,
              price: false,
              includedItems: false,
              excludedItems: false,
              allergens: false,
              isLactoseFree: false,
              isGlutenFree: false,
              isVegan: false,
              isVegetarian: false
            },
            groups: [{
              id: 0,
              code: "specialEmptyGroup",
              language: language,
              name: translate("specialEmptyGroup"),
              items: []
            }]
          }]
        }]
      })
    }
  }, [restaurant])

  const onRestaurantChange = (restaurantUpdate) => {
    setRestaurant({
      ...restaurant,
      ...restaurantUpdate
    })
  };

  return (
    <div>
      <>
      <div id="header">
      { !!tabs &&
              <div className="col-12 display-flex fix-margin-under padding-top-sx">
                <div className="left-header-style">
                  <div className="fake-field-class"></div>
                </div>
                <div className="center-header-style">
                  <NavBarScrollComponent
                    items={tabs}
                    setSelectedMenuItem={SetActiveTab}
                    translate={translate}
                    DefActiveTab={activeTab}
                  />
                </div>
              </div>
            }
      </div>
          {activeTab === "menu" && <AdminMenuComponent OpenEditModal={OpenEditModal} restaurantMenus={restaurant.menus} restaurantId={restaurant?.id} />}
          {activeTab === 'pictures' && <AdminPicturesComponent restaurantPictures={restaurant.pictures} restaurantId={restaurant?.id} />}
          {!!restaurant?.id && activeTab === 'basicData' && <AdminBasicComponent restaurant={restaurant} handleChange={onRestaurantChange} />}
          {activeTab === 'description' && <DescriptionEditorComponent restaurantCode={restaurantCode}/>}
        </>
    </div>);
}

export default MyPage;
