import "../../../css/filters.css";
import React, { useState, useCallback, useEffect } from "react";
import TypeFilterComponent from "../left/filters/TypeFilterComponent";
import PriceMaxFilterComponent from "./filters/PriceMaxFilterComponent";
import PriceMinFilterComponent from "./filters/PriceMinFilterComponent";
import CurrencyFilterComponent from "../left/filters/CurrencyFilterComponent";
import IncludedExcludedItemsFilterComponent from "../left/filters/IncludedExcludedItemsFilterComponent";
import FilterElementComponent from "../left/filters/FilterElementComponent";
import FilterSimpleElementComponent from "../left/filters/FilterSimpleElementComponent";
import AllergensExcludedFilterComponent from "../left/filters/AllergensExcludedFilterComponent";
import useScreenDetector from "../../../hooks/useScreenDetector";
import ToggleFilterComponent from "./filters/ToggleFilterComponent";
import { Message } from "rsuite";
import { useI18nContext } from '../../../i18n/context/context';

function FiltersComponent({ SetFilterData, config, SetFilterClose, filters = {} }) {
  const { translate } = useI18nContext();
  const { fixElementsStyle } = useScreenDetector();
  
  useEffect(() => {

    fixElementsStyle();
  }, [])

  const SetCategory = (category)=> {
    SetFilterData({
      category
    });
  }

  const SetDishType = (dishType)=> {
    SetFilterData({
      dishType
    });
  }

  const SetSize = (size)=> {
    SetFilterData({
      size
    });
  }

  const SetSelectedCurrency = (value)=> {
    SetFilterData({
      currency: value
    });
  }

  const SetIncludedExcludedItemsFilterData = (item) => {
    SetFilterData({
      includedItems: item.includedItems,
      excludedItems: item.excludedItems,
    });
  }

  const SetAllergensItemsFilterData = (item) => {
    SetFilterData({
      allergens: item.allergens
    });
  }

  const SetGlutenFree = (value) => {
    SetFilterData({glutenFree: value});
  }

  const SetLactoseFree = (value) => {
    SetFilterData({lactoseFree: value});
  }

  const SetVegetarian = (value) => {
    SetFilterData({vegetarian: value});
  }

  const SetVegan = (value) => {
    SetFilterData({vegan: value});
  }

  const [showFullInfo, setShowFullInfo] = useState(false);

  const GetFiltersInfo = useCallback(() => {
    if (showFullInfo) {
      return translate("filtersInfo");
    } else {
      return translate("filtersShortInfo");
    }
  }, [showFullInfo]);

  const ClickFiltersInfo = useCallback(() => {
    if (showFullInfo) {
      setShowFullInfo(false);
    } else {
      setShowFullInfo(true);
    }
  }, [showFullInfo]);

  useEffect(() => {
    if(!!config){
      SetSelectedCurrency(config.price.currency.items[0]);
    }
  }, [config])

  return (
    <div className="">
      {!!config.categories && !!config.categories.visible && <FilterElementComponent
        label={translate("category")}
        selected={!!filters.category}
        component={
          <TypeFilterComponent
            SetFilterValue={SetCategory}
            data={config.categories.items}
            value={filters.category}
            objectKey="category"
          />
        }
      />}

      {!!config.dishTypes && !!config.dishTypes.visible && <FilterElementComponent
        label={translate("dishType")}
        selected={!!filters.dishType}
        component={
          <TypeFilterComponent
            SetFilterValue={SetDishType}
            data={config.dishTypes.items}
            value={filters.dishType}
            objectKey="dishType"
          />
        }
      />}

      {!!config.variants && !!config.variants.visible && <FilterElementComponent
        label={translate("size")}
        selected={!!filters.size}
        component={
          <TypeFilterComponent
            SetFilterValue={SetSize}
            data={config.variants.items}
            value={filters.size}
            objectKey="priceVariants"
          />
        }
      />}

      {!!config.price && !!config.price.visible && 
        <FilterElementComponent
        label={translate("price")}
        selected={!!filters.minPrice || !!filters.maxPrice}
        component={
          <div className="price-flexbox">
            <PriceMinFilterComponent 
              SetFilterValue={(minPrice) => minPrice !== null && SetFilterData({minPrice: Number(minPrice)})}
              data={config.price}
              value={filters.minPrice}/>
            <PriceMaxFilterComponent 
              SetFilterValue={(maxPrice) => maxPrice !== null &&  SetFilterData({maxPrice: Number(maxPrice)})}
              data={config.price}
              value={filters.maxPrice}/>
          </div>
        }
        additionalComponent={
          <CurrencyFilterComponent 
            SetSelectedCurrency={SetSelectedCurrency}
            data={config.price.currency.items}
            value={filters.currency}
          />}
      />
      }

      {!!config.items && !!config.items.visible && <div className="margin-bottom-mx padding-left-xl filter-min-width">
        <span>
          <IncludedExcludedItemsFilterComponent
            SetFilterValue={SetIncludedExcludedItemsFilterData}
            data={config.items.items}
            value={{includedItems: filters.includedItems, excludedItems: filters.excludedItems}}
          />
        </span>
      </div>}

      {!!config.showExcludeAllergens && !!config.allergens && <div className="margin-bottom-mx padding-left-xl filter-min-width">
        <span>
          <AllergensExcludedFilterComponent
            values={filters.allergens}
            SetFilterValue={SetAllergensItemsFilterData}
            data={config.allergens.map(({ value }) => value).map((allergen) => ({text: translate(allergen), value: allergen}))}
          />
        </span>
      </div>}

      {!!config && !!config.showLactoseFree &&
        <FilterSimpleElementComponent
          onChange={SetLactoseFree}
          label={translate("isLactoseFree")}
          isSelected={filters.lactoseFree}
          component={(
            <ToggleFilterComponent
              SetFilterValue={SetLactoseFree}
              value={filters.lactoseFree}
            />
          )}
        />
      }

      {!!config && !!config.showGlutenFree &&
        <FilterSimpleElementComponent
          onChange={SetGlutenFree}
          isSelected={filters.glutenFree}
          label={translate("isGlutenFree")}
          component={(
            <ToggleFilterComponent
              SetFilterValue={SetGlutenFree}
              value={filters.glutenFree}
            />
          )}
        />
      }

      {!!config && !!config.showVegan &&
        <FilterSimpleElementComponent
          onChange={SetVegan}
          isSelected={filters.vegan}
          label={translate("isVegan")}
          component={(
            <ToggleFilterComponent
              SetFilterValue={SetVegan}
              value={filters.vegan}
            />
          )}
        />
      }

      {!!config && !!config.showVegetarian &&
        <FilterSimpleElementComponent
          onChange={SetVegetarian}
          isSelected={filters.vegetarian}
          label={translate("isVegetarian")}
          component={(
            <ToggleFilterComponent
              SetFilterValue={SetVegetarian}
              value={filters.vegetarian}
            />
          )}
        />
      }

      <div className="margin-bottom-mx padding-left-xl display-flex col-11 filter-min-width">
        <Message
          showIcon
          type="info"
          className="filter-info-style"
          // onClick={() => ClickFiltersInfo()}
          >
          {translate("filtersInfo")}
        </Message>
      </div>
    </div>
  );
}

export default FiltersComponent;
