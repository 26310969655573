import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Input, InputGroup, InputNumber, Radio, RadioGroup, Toggle } from 'rsuite';
import { IoClose } from "react-icons/io5";
import SelectDirectiveComponent from "../../../../directives/SelectDirectiveComponent";
import AutoCompleteDirectiveComponent from "../../../../directives/AutoCompleteDirectiveComponent";
import DropzoneDirectiveComponent from "../../../../directives/DropzoneDirectiveComponent";
import { useI18nContext } from "../../../../i18n/context/context";
import { useAdminApiContext } from '../../../../hooks/admin/context';
import { ApiLibrary } from '../../../../helpers/api/ApiLibrary';
import { Allergen, Currency, generateGuid } from '../../../../helpers/formatting';
import MenuItemEditPriceComponent from "./MenuItemEditPriceComponent";
import MenuItemEditPriceVariantComponent from "./MenuItemEditPriceVariantComponent";
import TooglerDirectiveComponent from "../../../../directives/TooglerDirectiveComponent";
import LoaderDirectiveComponent from "../../../../directives/LoaderDirectiveComponent";


function MenuItemEditDefComponent({ model, setChanges, setCanSave, canSave, validModel }) {
  const { translate, language } = useI18nContext();
  const { state: { selectedCard } } = useAdminApiContext();
  const [filtersList, setFiltersList] = useState(null);
  const [myModel, setMyModel] = useState({...model, priceType: !!model?.priceVariants && model?.priceVariants.length > 0 ? 'pricesVariants' : 'pricesOneVariant'});
  const [myCanSave, setMyCanSave] = useState(false)
  const [count, setCount] = useState(1)
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async() => {
      const response = await new ApiLibrary(language).menu.cards.getFiltersOfCard(selectedCard.id);

      if (!response.data) {
        return;
      }

      setFiltersList(response.data);
    };

    if (selectedCard) {
      fetchData();
    }
  }, [selectedCard, language])

  useEffect(() => {
    if(!!myModel){
      setCanSave(!!myModel?.dishName && (!validModel || !validModel.includes(myModel?.dishName)))
      setMyCanSave(!!myModel?.dishName && (!validModel || !validModel.includes(myModel?.dishName)))
    }
  }, [myModel])
  
  const availableDishItems = useMemo(() => (
    filtersList?.dishComponents?.items.filter(({ value }) => !myModel.dishItems.map(({value}) => value).includes(value)) ?? []
  ), [myModel, filtersList]);

  const availableVariants = useMemo(() => (
    filtersList?.variants?.items.filter(({ value }) => !myModel.priceVariants.map(({id}) => id).includes(value)) ?? []
  ), [myModel, filtersList]);

  const allergens = useMemo(() => (
    Object.values(Allergen)
      .map((value) => ({ value, text: translate(value) }))
      .filter(({ value }) => !myModel?.allergens.includes(value))
  ), [myModel]);

  const currencies = ["PLN", "EUR", "DOLAR"]

  const onChangeItemsTextValue = (idx, val) => {
    const dishItems = [...myModel.dishItems];
    if(dishItems[idx] != null) {
      dishItems[idx] = val;
    } else {
      dishItems.push(val)
    }

    const updatedModel = {
      ...myModel,
      dishItems: dishItems.filter(x => !!x)
    }

    setMyModel(updatedModel);
    setChanges(updatedModel);
  }

  const onChangeAllergensTextValue = (idx, val) => {
    const allergens = [...myModel.allergens];
    if(idx != null) {
      allergens[idx] = val;
    } else {
      allergens.push(val)
    }

    const updatedModel = {
      ...myModel,
      allergens: allergens.filter(x => !!x)
    }

    setMyModel(updatedModel);
    setChanges(updatedModel);
  }

  const onChangeDishTypeValue = (val) => {
    const updatedModel = {
      ...myModel,
      dishType: val
    }

    setMyModel(updatedModel);
    setChanges(updatedModel);
  }

  const changeValue = (field, val) => {
    let thisModel = myModel;
    thisModel[field] = val;
    setChanges(thisModel);
    setMyModel(thisModel);
    setCount(count+1)
  }

  const setPhotoUrl = async ([file]) => {
    let thisModel = {...myModel};
    if (file) {
      try {
        setIsLoading(true);
        const response = await new ApiLibrary().common.add(file);
        setIsLoading(false);
        thisModel["urlFirstPhoto"] = response.data?.url;
      } catch (error) {
        console.error(error);
      }
    } else {
      await new ApiLibrary().common.removeByUrl(myModel.urlFirstPhoto);
      thisModel["urlFirstPhoto"] = null;
    }
    setChanges(thisModel);
    setMyModel(thisModel)
  }

  const setPriceFunction = (idx, val) => {
    const prices = [...myModel.prices];
    if(prices[idx] != null) {
      prices[idx].value = val;
    } else {
      prices.push({value: val, currency: ""})
    }

    const updatedModel = {
      ...myModel,
      prices: prices.filter(x => !!x.value)
    }

    setMyModel(updatedModel);
    setChanges(updatedModel);
  }

  const setCurrencyFunction = (idx, curr) => {
    const prices = [...myModel.prices];
    prices[idx].currency = curr;

    const updatedModel = {
      ...myModel,
      prices: prices.filter(x => !!x.value)
    }

    setMyModel(updatedModel);
    setChanges(updatedModel);
  }

  const setVariantFunction = (idx, variant) => {
    const myVariants = [...myModel.priceVariants];

    if(myVariants[idx] != null) {
      myVariants[idx] = variant;
    } else {
      myVariants.push(variant)
    }

    const updatedModel = {
      ...myModel,
      priceVariants: myVariants.filter(x => !!x.variant)
    }

    setMyModel(updatedModel);
    setChanges(updatedModel);
  }

  const changePriceType = (val) => {
    const updatedModel = {
      ...myModel,
      priceType: val,
    }
    if(val == 'pricesOneVariant'){
      updatedModel.priceVariants = [];
    }
    else{
      updatedModel.prices = [];
    }

    setMyModel(updatedModel);
    setChanges(updatedModel);
  }

  return (
    <div className="edit-item-style">
      {isLoading &&
        <LoaderDirectiveComponent />
      }
      <div className="edit-item-field">
        <Input className={` edit-item-field-text ${!myCanSave ? "edit-input-error" : ""} ${!!myModel?.dishName ? "sd-selected" : ""}`}
          placeholder={translate("dishName")}
          defaultValue={myModel?.dishName}
          onChange={(val, e) => {
            changeValue("dishName", val);
            setCanSave(!!val && (!validModel || !validModel.includes(val)))
            setMyCanSave(!!val && (!validModel || !validModel.includes(val)))
          }}
        />
      </div>

      <div className="edit-item-field">
        {!!myModel &&
          <div>
            <DropzoneDirectiveComponent
              onUpload={setPhotoUrl}
              item={myModel?.urlFirstPhoto}
              emptyClassName="menu-item-edit-dropzone-empty"
              className="dropzone-def-style"
              playIfFocus={true}
              currentTime={0}
            />  
          </div>
        }
      </div>

      <div className="edit-item-field">
        <AutoCompleteDirectiveComponent 
          clearable={false}
          value={myModel?.dishType} 
          placeholder={translate("type")}
          onChange={(val) => onChangeDishTypeValue(val)} 
          options={filtersList?.dishTypes?.items ?? []}
          />
      </div>

      <div className="edit-item-field">
        <Input as="textarea" rows={4} 
          className={`${!!model?.description ? "sd-selected" : ""}`}
          onChange={(val, e) => changeValue("description", val)}
          defaultValue={model?.description}
          placeholder={translate("description")} 
        />
      </div>

      <div className="edit-item-field">
        <div className="label margin-bottom-sm">{translate("dishItems")}</div>
        <div className="edit-items-field">
          {!!count &&
            [...(myModel?.dishItems ?? []), { text: '', value: `new_${generateGuid()}` }].map((item, idx) => (
              <div key={item?.value ?? idx} className="edit-items-item">
                <AutoCompleteDirectiveComponent 
                  value={item}
                  onChange={(val) => onChangeItemsTextValue(idx, val)} 
                  options={availableDishItems}
                  placeholder={translate("item")}
                />
              </div>)
            )
          }        
        </div>
      </div>

      <div className="edit-item-field">
        <div className="label margin-bottom-sm">{translate("allergens")}</div>
        <div className="edit-items-field">
          {[...(myModel?.allergens ?? []), null].map((value, idx) => (
            <div key={value} className="edit-items-item">
              <SelectDirectiveComponent 
                placeholder={translate("allergen")}
                options={allergens}
                // hiddenOptionsText={myModel.allergens}
                onChange={(val) => onChangeAllergensTextValue(idx, val)}
                selectedOption={value ? {text: translate(value), value} : null}/>
            </div>)
          )
          }        
        </div>
      </div>

      <div className="edit-allergens-safe-field">
        <div className="edit-allergens-safe">
          <div className="margin-bottom-mx padding-left-xl display-flex col-11 filter-min-width margin-auto">
            <div className={`padding-right-xl label cursor-pointer ${!!count && myModel?.isLactoseFree ? "selected-label" : ""}`} onClick={(e) => changeValue("isLactoseFree", !myModel?.isLactoseFree)}>{translate("isLactoseFree")}</div>
            <div className="">{!!count && !!myModel && <Toggle checked={myModel?.isLactoseFree} onChange={(val) => changeValue("isLactoseFree", val)} />}</div>
          </div>
        </div>
        <div className="edit-allergens-safe">
          <div className="margin-bottom-mx padding-left-xl display-flex col-11 filter-min-width margin-auto">
            <div className={`padding-right-xl label cursor-pointer ${!!count && myModel?.isGlutenFree ? "selected-label" : ""}`} onClick={(e) => changeValue("isGlutenFree", !myModel?.isGlutenFree)} >{translate("isGlutenFree")}</div>
            <div className="">{!!count && !!myModel && <Toggle checked={myModel?.isGlutenFree} onChange={(val) => changeValue("isGlutenFree", val)} />}</div>
          </div>
        </div>
        <div className="edit-allergens-safe">
          <div className="margin-bottom-mx padding-left-xl display-flex col-11 filter-min-width margin-auto">
            <div className={`padding-right-xl label cursor-pointer ${!!count && myModel?.isVegetarian ? "selected-label" : ""}`} onClick={(e) => changeValue("isVegetarian", !myModel?.isVegetarian)} >{translate("isVegetarian")}</div>
            <div className="">{!!count && !!myModel && <Toggle checked={myModel?.isVegetarian} onChange={(val) => changeValue("isVegetarian", val)} />}</div>
          </div>
        </div>
        <div className="edit-allergens-safe">
          <div className="margin-bottom-mx padding-left-xl display-flex col-11 filter-min-width margin-auto">
            <div className={`padding-right-xl label cursor-pointer ${!!count && myModel?.isVegan ? "selected-label" : ""}`} onClick={(e) => changeValue("isVegan", !myModel?.isVegan)} >{translate("isVegan")}</div>
            <div className="">{!!count && !!myModel && <Toggle checked={myModel?.isVegan} onChange={(val) => changeValue("isVegan", val)} />}</div>
          </div>
        </div>
      </div>

      <div className="edit-item-field margin-bottom-xl">
        <div className="label margin-bottom-sm">{translate("price")}</div>
        <div className="edit-price-field">
          <div className="price-radio-style">
            <TooglerDirectiveComponent isSelectedItemColor value={myModel?.priceType} setValue={changePriceType} items={[{text: translate("pricesOneVariant"), value: 'pricesOneVariant'}, {text: translate("pricesVariants"), value: 'pricesVariants'}]} />
          </div>

          <div className="price-body-container">
              {myModel?.priceType == "pricesOneVariant" && <MenuItemEditPriceComponent prices={myModel?.prices} currencies={currencies} setPrice={setPriceFunction} setCurrency={setCurrencyFunction} />}
              

              {myModel?.priceType == "pricesVariants" && 
                <div className="margin-bottom-xl">
                  <MenuItemEditPriceVariantComponent variants={availableVariants} priceVariants={myModel?.priceVariants} currencies={currencies} setVariant={setVariantFunction} />
                </div>
              }
          </div>    
        </div>
      </div>
    </div>
  );
}

export default MenuItemEditDefComponent;
