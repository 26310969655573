import vegetarian from "../../assets/images/vegetarian-icon.png";
import vegan from "../../assets/images/vegan-icon.png";
import lactoseFree from "../../assets/images/lactose-free-icon.png";
import glutenFree from "../../assets/images/gluten-free.png";
import React, { useEffect, useMemo } from "react";
import IconWithTooltipComponent from "../../directives/IconWithTooltipComponent";
import { IoClose } from "react-icons/io5";
import { GoScreenFull } from "react-icons/go";
import { MdFitScreen } from "react-icons/md";
import { MdFullscreen } from "react-icons/md";
import { SlSizeFullscreen } from "react-icons/sl";
import { AiOutlineFullscreen } from "react-icons/ai";
import { MdOutlineFullscreen } from "react-icons/md";
import _ from 'lodash';
import { useI18nContext } from '../../i18n/context/context';
import { getFormattedCurrency } from '../../helpers/formatting';

function HomeDishItemForRestaurantComponent({ OpenModal, dish }) {
  const { translate } = useI18nContext();

  const dishVariants = useMemo(() => (
    dish.priceVariants.reduce((allVariants, {variant, prices}) => ({
      ...allVariants,
      [variant]: prices.reduce((allPrices, {currency, value}) => ({
        ...allPrices,
        [currency]: value.toFixed(2).toString().concat(" ").concat(getFormattedCurrency(currency))
      }), {})
    }), {})
  ), [dish]);

  const getImage = useMemo(() => {
    return (
      <div className="hm-item-picture-container">
        <MdFullscreen className="hm-full-screen-icon"/>
          <img
            src={dish.urlFirstPhoto}
            className="hm-item-img-style"
            onClick={() => {
              OpenModal(dish.urlFirstPhoto);
            }}
          />
          
        </div>
    )
  }, [])

  return (
    <div>
      <div className="hm-item-style">
        {getImage}
        <div className="hm-item-details-style">
          <div className="hm-item-details-inside-style">
            <div className="hm-item-name-style">{dish.dishName}</div>
            <div className="hm-item-element-style hm-item-description-style">
              <span>{dish.description}</span>
            </div>
            <div className="hm-item-element-style">
              <span className="hm-item-label">{translate("elements")}</span>
              <span className="hm-item-description-style">
                {dish.dishItems.map(({text}, index) => (
                  <span key={index}>{text}{index < dish.dishItems.length-1 && ","} </span>
                ))}
              </span>
            </div>
            {
              (dish.isVegetarian || dish.isVegan || dish.isLactoseFree || dish.isGlutenFree) && (
              <div className="hm-item-element-style">
                <span className="margin-right-sm">
                  {dish.isVegetarian && (
                   <IconWithTooltipComponent icon={vegetarian} text={translate("vegetarian")} />
                  )}
                </span>
                <span className="margin-right-sm">
                 {dish.isVegan && (
                    <IconWithTooltipComponent icon={vegan} text={translate("vegan")} />
                 )}
                </span>
                <span className="margin-right-sm">
                 {dish.isLactoseFree && (
                   <IconWithTooltipComponent icon={lactoseFree} text={translate("lactoseFree")} />
                 )}
                </span>
                <span className="margin-right-sm">
                 {dish.isGlutenFree && (
                   <IconWithTooltipComponent icon={glutenFree} text={translate("glutenFree")} />
                 )}
               </span>
              </div>
              )
            }
            
            {Object.entries(dishVariants).map(([variant, prices]) => (
                <div key={variant} className="display-flex">
                  <div>
                    <span className="item-price-size-style padding-right-xl">{variant}</span>
                  </div>
                  <div className="display-flex">
                    {Object.entries(prices).map(([currency, price]) => (
                      <div key={currency}>
                        <span className="padding-right-xl">{price}</span>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeDishItemForRestaurantComponent;
