/* eslint-disable react-hooks/exhaustive-deps */
import "../App.css";
import "../css/dish.css";
import "../css/global.css";
import "../css/admin.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useI18nContext } from "../i18n/context/context";
import { ApiLibrary } from '../helpers/api/ApiLibrary';
import { useState, useEffect } from "react";
import { Input, Button } from 'rsuite';
import SelectDirectiveComponent from "../directives/SelectDirectiveComponent"
import AutoCompleteDirectiveComponent from "../directives/AutoCompleteDirectiveComponent";
import AdminBasicMapComponent from "../components/admin/basic/AdminBasicMapComponent";
import ToggleWithLabel from "../components/ToggleWithLabel";
import LoaderDirectiveComponent from "../directives/LoaderDirectiveComponent";
import Modal from '@mui/material/Modal';

function CreateNewRestaurant() {
  const { translate, language } = useI18nContext();
  const [isEnabled, setToogleEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [savedResponse, setSavedResponse] = useState(null); 
  const restaurantTypes = [{id: 0, text: "Winiarnio-kawiarnia"}, {id: 1, text: "Polskie jedzenie"}]
  const langs = [{value: "pl", text: "polski"}, {value: 'en-GB', text: "angielski"}]

  const restaurant = {
    restaurantName: null,
    defaultLang: langs[0],
    restaurantType: null,
    address: {
        country: "Polska",
        city: null,
        street: null,
        streetNumber: null,
        houseNumber: null
    },
    location: {
      x: 50.060913,
      y: 19.937478
    },
    extraConfig: {
      defString: translate("default", langs[0].value),
      defUrl: window.location.origin
    }
  }
  const [model, setModel] = useState(restaurant)

  const setLocation = (location) => {
    let thisModel = {...model};
    thisModel.location = {x: location.lat, y: location.lng};
    setModel(thisModel);
  }

  const handleSave = async () => {
    setIsLoading(true);
    const response = await new ApiLibrary(language).restaurants.general.createNewRestaurant(model, language);
    setIsLoading(false);
    if (!!response?.data) {
      setSavedResponse(response.data);
    }
  }

  return (
    <>
      {isLoading &&
        <LoaderDirectiveComponent />
      }
      {!!savedResponse &&
        <>
          <Modal open={true}>
            <div className="restaurant-start-inside">
              <div className="margin-xl">
                {savedResponse.activationKey}
                <Button
                  color="green"
                  appearance="primary"
                  onClick={() => navigator.clipboard.writeText(savedResponse.activationKey)}
                >
                  {translate('copyToClipBoard')}
                </Button>
              </div>
              <div className="margin-xl">
                {savedResponse.adminUrl}
                <Button
                  color="green"
                  appearance="primary"
                  onClick={() => window.open(savedResponse.adminUrl)}
                >
                  {translate('openAdminInNewTab')}
                </Button>
              </div>
              <div className="margin-xl">
                {savedResponse.url}
                <Button
                  color="green"
                  appearance="primary"
                  onClick={() => window.open(savedResponse.url)}
                >
                  {translate('openClientSideInNewTab')}
                </Button>
              </div>
            </div>
          </Modal>
        </>
      }

      <div className="admin-basic-data-container">
        <div className="admin-basic-data-inside-container">
          <Input className={`admin-basic-edit-item-field-text font-xl ${!model.restaurantName ? "edit-input-error" : ""} ${!!model.restaurantName? "sd-selected" : ""}`}
                placeholder={translate("name")}
                defaultValue={model.restaurantName}
                onChange={(val, e) => {
                  let thisModel = {...model};
                  thisModel.restaurantName = val;
                  setModel(thisModel);
                }}
              />

          <div className="admin-basic-edit-item-field-text font-xl margin-top-xl">
            <SelectDirectiveComponent 
              placeholder={translate("defaultLanguage")}
              options={langs}
              selectedOption={model.defaultLang}
              clearable={false}
              onChange={(val) => {
                let thisModel = {...model};
                let lang = langs.filter(x => x.value == val)[0]
                thisModel.defaultLang = lang;
                thisModel.extraConfig.defString = translate("default", lang.value)
                setModel(thisModel);
              }}/>
          </div>

          <div className="admin-basic-edit-item-field-text font-xl margin-top-xl">
          {/* <Input className={`admin-basic-edit-item-field-text font-xl ${!model.restaurantType ? "edit-input-error" : ""} ${!!model.restaurantType? "sd-selected" : ""}`}
                placeholder={translate("restaurantType")}
                defaultValue={model.restaurantType}
                onChange={(val, e) => {
                  let thisModel = {...model};
                  thisModel.restaurantType = val;
                  setModel(thisModel);
                }}
              /> */}
            <AutoCompleteDirectiveComponent inputClassName={`font-xl ${!model.restaurantType?.text ? "edit-input-error" : ""}`}
                clearable={false}
                hasError={!model.restaurantType?.text}
                value={model?.restaurantType}
                onChange={(val) => {
                  let thisModel = {...model};
                  let types = restaurantTypes.filter(x => x.text?.toLocaleLowerCase() == val?.text?.toLocaleLowerCase())
                  thisModel.restaurantType = types.length > 0 ? types[0] : val;
                  setModel(thisModel);
                }} 
                options={!!model.restaurantType?.text ? restaurantTypes.filter(x => x.text?.toLocaleLowerCase().includes(model.restaurantType.text?.toLocaleLowerCase())) : []}
                placeholder={translate("restaurantType")}
              /> 

          </div>
          
          <div className="margin-top-xl">
            <div className="label margin-bottom-sm">{translate("address")}</div>
            <div className="edit-items-field">
              <div className="margin-mx admin-basic-address-city">
                <Input className={` admin-basic-edit-item-field-text font-xl  ${!model.address?.city ? "edit-input-error" : ""} ${!!model.address?.city ? "sd-selected" : ""}`}
                  placeholder={translate("city")}
                  defaultValue={model?.address?.city}
                  onChange={(val, e) => {
                    let thisModel = {...model};
                    thisModel.address.city = val;
                    setModel(thisModel);
                  }}
                />
            </div>
            <div className="margin-mx admin-basic-address-street">
              <Input className={` admin-basic-edit-item-field-text font-xl  ${!model.address?.street ? "edit-input-error" : ""} ${!!model.address?.street ? "sd-selected" : ""}`}
                placeholder={translate("street")}
                defaultValue={model?.address?.street}
                onChange={(val, e) => {
                  let thisModel = {...model};
                  thisModel.address.street = val;
                  setModel(thisModel);
                }}
              />
            </div>
            <div className="margin-mx admin-basic-address-street-number">
              <Input className={` admin-basic-edit-item-field-text font-xl  ${!model.address?.streetNumber ? "edit-input-error" : ""} ${!!model.address?.streetNumber? "sd-selected" : ""}`}
                placeholder={translate("streetNumber")}
                defaultValue={model?.address?.streetNumber}
                onChange={(val, e) => {
                  let thisModel = {...model};
                  thisModel.address.streetNumber = val;
                  setModel(thisModel);
                }}
              />
            </div>
            </div>
          </div>
    
          <div className="display-flex">
            <AdminBasicMapComponent isEditable={isEnabled} restaurant={restaurant} setLocation={setLocation}/>
            <ToggleWithLabel
              label={translate("editMarkerMap")}
              checked={isEnabled}
              onChange={setToogleEnabled}
            />
          </div>
        </div>
        

        <div className='save-button-container'>
          <Button
            color="green"
            appearance="primary"
            onClick={() => handleSave()}
            disabled={!(model.restaurantName && model.address.city && model.address.street && model.address.streetNumber && model.restaurantType?.text)}
          >
            {translate('save')}
          </Button>
        </div>

      </div>

    </>
    )
}


export default CreateNewRestaurant;
