// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.id-input-hour-body{
    padding: 7px 0px 7px 5px;
    border-radius: 6px;
    display: flex;
    vertical-align: middle;
    border: 1px solid #e5e5ea;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.id-input-hour-body:hover{
    border-color: var(--sefooColor) !important;
    color: var(--sefooColor) !important;
}

.id-hour-not-empty{
    border-color: var(--sefooColor);
}

.react-time-picker__wrapper{
    border: none !important;
}

.react-time-picker__inputGroup{
    min-width: 40px !important;
}

.react-time-picker__inputGroup__input:invalid{
    background: var(--backgroundColor) !important;
}`, "",{"version":3,"sources":["webpack://./src/directives/InputHoursDirectiveStyles.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,yBAAyB;IACzB,0BAAkB;IAAlB,uBAAkB;IAAlB,kBAAkB;AACtB;;AAEA;IACI,0CAA0C;IAC1C,mCAAmC;AACvC;;AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,6CAA6C;AACjD","sourcesContent":[".id-input-hour-body{\n    padding: 7px 0px 7px 5px;\n    border-radius: 6px;\n    display: flex;\n    vertical-align: middle;\n    border: 1px solid #e5e5ea;\n    width: fit-content;\n}\n\n.id-input-hour-body:hover{\n    border-color: var(--sefooColor) !important;\n    color: var(--sefooColor) !important;\n}\n\n.id-hour-not-empty{\n    border-color: var(--sefooColor);\n}\n\n.react-time-picker__wrapper{\n    border: none !important;\n}\n\n.react-time-picker__inputGroup{\n    min-width: 40px !important;\n}\n\n.react-time-picker__inputGroup__input:invalid{\n    background: var(--backgroundColor) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
