import vegetarian from "../../assets/images/vegetarian-icon.png";
import vegan from "../../assets/images/vegan-icon.png";
import lactoseFree from "../../assets/images/lactose-free-icon.png";
import glutenFree from "../../assets/images/gluten-free.png";
import React, { useMemo, useState } from "react";
import IconWithTooltipComponent from "../../directives/IconWithTooltipComponent";
import { useI18nContext } from '../../i18n/context/context';
import CloudinaryImage from '../CloudinaryImage';
import ModalItemComponent from "../modal/ModalItemComponent";
import VideoPlayerComponent from "../base/VideoPlayerComponent";
import DishPrices from './components/DishPrices';
import DishItemPriceComponent from "./components/DishItemPriceComponent";

function DishItemForRestaurantComponent({ dish }) {
  const { translate } = useI18nContext();
  const [openItemModal, setOpenItemModal] = useState(false); 

  const getImage = useMemo(() => {
    if(!dish || !dish.urlFirstPhoto)
      return (<></>)
    if(dish.urlFirstPhoto.slice(-4) == ".jpg" || dish.urlFirstPhoto.slice(-4) == ".png"){
      return (

        <div className="">
          {dish.urlFirstPhoto.includes('cloudinary') ? (
            <CloudinaryImage
              alt={dish.dishName}
              src={dish.urlFirstPhoto}
              cropMode="fill"
              width={150}
              height={150}
              className="item-img-style"
            />
          ) : (
            <img
              alt={dish.dishName}
              src={dish.urlFirstPhoto}
              className="item-img-style"
            />
          )}
        </div>
      )
    }
    else{
      return (
        <div className='admin-dish-picture-container'>
          <div>
            <VideoPlayerComponent
              id="demo-player"
              publicId={dish.urlFirstPhoto}
              className="dish-video-style"
              autoplay={false}
              playIfFocus={true}
              currentTime={0}
            />
          </div>
        </div>
      )
    }
  }, [dish])

  return (
    <div>
      <div>
        {openItemModal &&
          <ModalItemComponent dish={dish} setOpenModal={() => setOpenItemModal(false)}/>
        }
      </div>
      <div className="item-style" onClick={() => setOpenItemModal(true)}>
        {getImage}
        <div className="item-details-style">
          <div className="item-details-inside-style">
            <div className="item-name-style">{dish.dishName}</div>
            {!!dish.description && 
              <div className="item-element-style item-description-desc-style">
                <span>{dish.description}</span>
              </div>
            }
            {!!dish.dishType && 
                <div className="item-element-style">
                <span className="item-label">{translate("type")}</span>
                  <span className="item-description-style">{dish.dishType?.text}</span>
                </div>
              }
            {!!dish.dishItems && dish.dishItems.length > 0 &&
            <div className="item-element-style">
              <span className="item-label">{translate("elements")}</span>
              <span className="item-description-style">
                {dish.dishItems.map(({text}, index) => (
                  <span key={index}>{text}{index < dish.dishItems.length-1 && ","} </span>
                ))}
              </span>
            </div>
            }
            {!!dish.allergens && dish.allergens.length > 0 && 
              <div className="item-element-style">
                <span className="item-label">{translate("allergens")}</span>
                <span className="item-description-style">
                  {dish.allergens.map((item, index) => (
                    <span key={index}>{translate(item)}{index < dish.allergens.length-1 && ","} </span>
                  ))}
                </span>
              </div>
            }
            { (dish.isVegetarian || dish.isVegan || dish.isLactoseFree || dish.isGlutenFree) &&
              <div className="item-element-style">
                <span className="margin-right-sm">
                  {dish.isVegetarian && (
                    <IconWithTooltipComponent icon={vegetarian} text={translate("vegetarian")} />
                  )}
                </span>
                <span className="margin-right-sm">
                  {dish.isVegan && (
                    <IconWithTooltipComponent icon={vegan} text={translate("vegan")} />
                  )}
                </span>
                <span className="margin-right-sm">
                  {dish.isLactoseFree && (
                    <IconWithTooltipComponent icon={lactoseFree} text={translate("lactoseFree")} />
                  )}
                </span>
                <span className="margin-right-sm">
                  {dish.isGlutenFree && (
                    <IconWithTooltipComponent icon={glutenFree} text={translate("glutenFree")} />
                  )}
                </span>
              </div>
            }
            {/* <DishPrices
              className="item-price-style display-flex"
              prices={dish.prices}
              variants={dish.priceVariants}
            /> */}
            <DishItemPriceComponent prices={dish.prices} priceVariants={dish.priceVariants} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DishItemForRestaurantComponent;
