import { useI18nContext } from '../../../i18n/context/context';
import QuillEditor from '../QuillEditor';
import Container from 'rsuite/Container';
import Button from 'rsuite/Button';
import LoaderDirectiveComponent from '../../../directives/LoaderDirectiveComponent';
import { useEffect, useState } from 'react';
import ToggleWithLabel from '../../ToggleWithLabel';
import { useQuillEditor } from '../useQuillEditor';
import { ApiLibrary } from '../../../helpers/api/ApiLibrary';
import TooglerDirectiveComponent from '../../../directives/TooglerDirectiveComponent';
import DropzoneDirectiveComponent from '../../../directives/DropzoneDirectiveComponent';

const PosterEditorComponent = ({restaurantId}) => {
  const { quillRef, getContent, setContent, quill } = useQuillEditor();
  const { language, translate } = useI18nContext();
  const [isEnabled, setEnabled] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [posterType, setPosterType] = useState("editor");
  const [media, setMedia] = useState(undefined)

  const setChanges = (val) => {
    setCanSave(true)
  }

  useEffect(() => {
    if(!!quill){
      if (quill && setChanges){
        quill.on("text-change", (delta) => {
          setChanges(quill.root.innerHTML)
        });
      }
    }
  }, [quill])

  const setToogleEnabled = async (val) => {
    setEnabled(val)
    setCanSave(true)
    setIsLoading(true)
      await new ApiLibrary(language).restaurants.posters.updateOrCreate(restaurantId, {
        isEnabled: val,
        content: getContent(),
        media: media
      })
      setIsLoading(false)
  }

  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await new ApiLibrary(language).restaurants.posters.get(restaurantId, language);

        if (!response.data) {
          return;
        }

        if(response.data.media){
          setPosterType("media")
          setMedia(response.data.media)
        }

        setContent(response.data.content);
        setEnabled(response.data.isEnabled);
      } catch(error) {
        throw new Error('No poster available!');
      }
    }

    if (setContent) {
      loadData();
    }
  }, [setContent, restaurantId])

  const handleSave = async () => {
    try {
      setIsLoading(true)
      await new ApiLibrary(language).restaurants.posters.updateOrCreate(restaurantId, {
        isEnabled,
        content: getContent(),
        media: ""
      })
      setIsLoading(false)
      setCanSave(false)
      setMedia("")
    } catch(error) {}
  }

  if (!restaurantId) {
    return 'No restaurant selected';
  }

  const changePosterType = (val) => {
    setPosterType(val)
  }

  const setPhotoUrl = async ([file]) => {
    if (file) {
      setIsLoading(true);
      try {
        const response = await new ApiLibrary().common.add(file);
        await new ApiLibrary(language).restaurants.posters.updateOrCreate(restaurantId, {
          isEnabled,
          content: "",
          media: response.data?.url
        })
        setContent("")
        setMedia(response.data?.url)
      } catch (error) {
        console.error(error);
      }
    } else {
      await new ApiLibrary().common.removeByUrl(media);
      await new ApiLibrary(language).restaurants.posters.updateOrCreate(restaurantId, {
        isEnabled,
        content: "",
        media: ""
      })
      setMedia("") 
    }
    setIsLoading(false);
  }

  return (
    <Container className="quill-editor-container">
      {isLoading &&
          <LoaderDirectiveComponent />
        }
      <div className='margin-top-mx'>
        <ToggleWithLabel
          label={translate("enablePoster")}
          checked={isEnabled}
          onChange={setToogleEnabled}
        />
      </div>
      
      <div className={`margin-top-mx ${!isEnabled ? "disabled-style" : ""}`}>
        <TooglerDirectiveComponent isSelectedItemColor value={posterType} setValue={changePosterType} items={[{text: translate("editor"), value: 'editor'}, {text: translate("media"), value: 'media'}]} />
      </div>

      {posterType == "media" &&
        <div className={`margin-top-mx ${!isEnabled ? "disabled-style" : ""}`}>
          <DropzoneDirectiveComponent
            onUpload={setPhotoUrl}
            item={media}
            emptyClassName="menu-item-edit-dropzone-empty"
            className="dropzone-def-style"
            playIfFocus={true}
            currentTime={0}
          />  
        </div>
      }

      <div className={`${posterType != "editor" ? 'display-none' : ""}`}>
        <QuillEditor
          quillRef={quillRef}
          disabled={!isEnabled}
        />
      </div>

      {posterType == "editor" &&
      <>
        <div className='save-button-container'>
        <Button
          color="green"
          appearance="primary"
          onClick={() => handleSave(getContent())}
          // disabled={!canSave}
        >
          {translate('save')}
        </Button>
      </div>
      </> 
      }
    </Container>
  );
};

export default PosterEditorComponent;
