/* eslint-disable react-hooks/exhaustive-deps */
import "../../App.css";
import "../../css/dish.css";
import "../../css/global.css";
import "../../css/admin.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect } from "react";
import { Button } from 'rsuite';
import { useI18nContext } from "../../i18n/context/context";
import LoaderDirectiveComponent from "../../directives/LoaderDirectiveComponent";
import SelectDirectiveComponent from "../../directives/SelectDirectiveComponent";
import { ApiLibrary } from "../../helpers/api/ApiLibrary";
import ColorSelectorComponent from "../../components/admin/ColorSelectorComponent";
import colors from '../../datas/colors.json'


function Configuration({ Restaurant, handleChange }) {
  const { translate, language, languageList } = useI18nContext();
  const [isLoading, setIsLoading] = useState(false);
  const [model, setModel] = useState(null);
  const colorsSelect = Object.keys(colors).map(color => ({value: color, text: <ColorSelectorComponent color={colors[color].firstColor} />}))

  useEffect(() => {
    if(Restaurant){
      let thisModel = {...Restaurant};
      thisModel.defaultLang = languageList.map(x => ({value: x.value, text: x.label})).filter(x => x.value == thisModel.defaultLang)[0].value
      setModel(thisModel);
    }
  }, [Restaurant])

  const handleSave = async () => {
    setIsLoading(true);
    await new ApiLibrary().restaurants.general.update(Restaurant.id, model)
    handleChange(model);
    setIsLoading(false);
    
    let root = document.querySelector(':root');
    let firstColor = colors.sefoo.firstColor;
    let secondColor = colors.sefoo.secondColor;
    let thirdColor = colors.sefoo.thirdColor;
    let fourthColor = colors.sefoo.fourthColor;
    let fifthColor = colors.sefoo.fifthColor;
    let sixthColor = colors.sefoo.sixthColor;
    if(Object.keys(colors).includes(model.color)){
      firstColor = colors[model.color].firstColor;
      secondColor = colors[model.color].secondColor;
      thirdColor = colors[model.color].thirdColor;
      fourthColor = colors[model.color].fourthColor;
      fifthColor = colors[model.color].fifthColor;
      sixthColor = colors[model.color].sixthColor;
    }
    root.style.setProperty('--sefooColor', firstColor);
    root.style.setProperty('--secondSefooColor', secondColor);
    root.style.setProperty('--rs-btn-primary-bg', firstColor);
    root.style.setProperty('--rs-btn-primary-hover-bg', secondColor);
    root.style.setProperty('--rs-menuitem-active-text', secondColor);
    root.style.setProperty('--rs-dropdown-item-text-active', secondColor);
    root.style.setProperty('--rs-btn-primary-active-bg', secondColor);
    root.style.setProperty('--rs-btn-ghost-text', firstColor);
    root.style.setProperty('--rs-btn-ghost-border', secondColor);
    root.style.setProperty('--rs-btn-ghost-hover-text', secondColor);
    root.style.setProperty('--rs-btn-ghost-hover-border', secondColor);
    root.style.setProperty('--rs-toggle-checked-hover-bg', firstColor);
    root.style.setProperty('--rs-input-focus-border', firstColor);
    root.style.setProperty('--secondBackgroundColor', thirdColor);
    root.style.setProperty('--rs-dropdown-item-bg-active', thirdColor);
    root.style.setProperty('--rs-listbox-option-hover-bg', fourthColor);
    root.style.setProperty('--rs-menuitem-active-bg', fourthColor);
    root.style.setProperty('--rs-message-info-bg', sixthColor);
    root.style.setProperty('--rs-message-info-icon', firstColor);
    root.style.setProperty('--rs-color-focus-ring', fifthColor);

    window.location.reload();
  }


  return (
    <>
      {isLoading &&
        <LoaderDirectiveComponent />
      }
      <div className="admin-header-underline" />
      {!!model && 
        <div className="admin-basic-data-container">
          <div className="admin-basic-data-inside-container margin-top-xl">
            <div className="admin-basic-edit-item-field-text font-xl">
              <SelectDirectiveComponent 
                  placeholder={translate("defaultLanguage")}
                  options={languageList.map(x => ({value: x.value, text: x.label}))}
                  selectedOption={languageList.map(x => ({value: x.value, text: x.label})).filter(x => x.value == model.defaultLang)[0]}
                  clearable={false}
                  onChange={(val) => {
                    let thisModel = {...model};
                    let lang = languageList.map(x => ({value: x.value, text: x.label})).filter(x => x.value == val)[0]
                    thisModel.defaultLang = lang.value;
                    setModel(thisModel);
                  }}/>
            </div>
            <div className="admin-basic-edit-item-field-text font-xl  margin-top-xl">
              <SelectDirectiveComponent 
                  options={colorsSelect}
                  selectedOption={colorsSelect.filter(x => x.value == model.color)[0]}
                  clearable={false}
                  onChange={(val) => {
                    let thisModel = {...model};
                    thisModel.color = val;
                    thisModel.extraConfig = {color: colors[val].firstColor, defUrl: window.location.origin}
                    setModel(thisModel);
                  }}/>
            </div>
          </div>
          <div className='save-button-container'>
            <Button
              color="green"
              appearance="primary"
              onClick={() => handleSave()}>
              {translate('save')}
            </Button>
          </div>
        </div>
      }
    </>
  );
}

export default Configuration;
